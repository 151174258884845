<template>
    <div class="cart-page">
        <div class="navbar">
            <el-icon @click="$router.back()" :size="24"><ArrowLeftBold /></el-icon>
            <h3>My Cart</h3>
            <div>
                <!-- <button class="deleteAll" @click="dialogClear = true" :disabled="!items?.length > 0">Delete All</button> -->
            </div>
        </div>
        <div class="container" v-if="items != null && items?.length !== 0">
            <div class="item" v-for="(item,itemIndex) in items" :key="(item,itemIndex)">
                <div class="logo-detail">
                    <img :src=" (item.imageUrl) ? getFullPathImage(item.imageUrl) : require('@/assets/images/food_default.jpeg')" alt/>
                    <div class="detail">
                        <p>{{ item.name }}</p>
                        <p> 
                            <span v-for="(topping, toppingIndex) in item?.topping" :key="(topping, toppingIndex)">{{ topping.remark }} 
                                <span v-if="toppingIndex < item?.topping?.length-1">,</span>
                            </span>
                        </p>
                        <p>{{ item.size }}</p>
                        <p><b style="color: #be0909bb;">${{ item.price.toFixed(2) }}</b></p>
                    </div>
                </div>
                <div class="right">
                    <div class="quatity">
                        <el-icon color="#616161" @click.prevent="decrement(itemIndex)" :size="24"><RemoveFilled /></el-icon>
                        <p>{{ item.quantity }}</p>
                        <el-icon color="#616161" @click.prevent="increment(itemIndex)" :size="24"><CirclePlusFilled /></el-icon>
                        <el-icon color="#be2843" style="cursor: pointer; margin-left: 10px" @click="deleteItem(itemIndex)" :size="20"><Delete /></el-icon>
                    </div>
                </div>
            </div>
        </div>
        <div class="emptyCart" v-else>
            <el-result
                class="empty"
                icon="warning"
                title="Opps, Empty"
                sub-title="Add items to get started">
                <template #extra>
                    <el-button @click="$router.back()" type="primary">Go Back</el-button>
                </template>
            </el-result>
        </div>
        
        <div class="price-checkout" v-if="items != null && items?.length !== 0">
            <div class="total-price">
                <h4 class="red-text">Order Price</h4>
                <h4 class="green-text">${{ subPrice.toFixed(2) }}</h4>
            </div>
            <el-button class="checkout-btn" @click="checkOut">CHECK OUT</el-button>
        </div>
    </div>
    <el-dialog
        v-model="dialogClear"
        title="Confirmation"
        width="90%"
        style="text-align: left; font-weight: 600;"
    >
        <span>Do you want to clear all items in cart?</span>
        <template #footer>
        <span class="dialog-footer">
            <el-button @click="dialogClear = false">No</el-button>
            <el-button type="danger" @click="clearCart()">Delete</el-button>
        </span>
        </template>
    </el-dialog>
</template>
<script>
import {ref} from 'vue';
import minus from '@/assets/icons/minus.png'
import plus from '@/assets/icons/plus.png'
import { ElMessage } from 'element-plus'
export default {
    name: "CartView",
    data() {
        return {
            minus, plus
        }
    },
    setup() {
        const quantity = ref(1);
        const totalPrice = ref(0);
        const items = ref([]);
        const dialogClear = ref(false);
        const quantityUpdated = ref('');

        return {
            quantity,
            totalPrice,
            items,
            dialogClear,
            quantityUpdated,
            
        }
    },
    created() {
        this.items = JSON.parse(localStorage.getItem("cart"));
    },
    methods: {
        increment(i) {
            this.items[i].quantity++;
            this.items[i].price = this.calPrice(i)
        },
        decrement(i) {
            if (this.items[i].quantity == 1)
                return

            this.items[i].quantity--;
            this.items[i].price = this.calPrice(i)
        },
        checkOut() {
            if (this.items != null && this.items?.length > 0) {
                localStorage.setItem("cart", JSON.stringify(this.items))
                let phone = localStorage.getItem("phone");
                let address = localStorage.getItem("address");
                console.log(this.cart);
                if (phone != null && address != null) {
                    this.$router.push({path: "/confirmOrder"});
                } else {
                    this.$router.push({path: "/addressInfo"});
                }
            } else {
                ElMessage.error('No item')
            }
            
        },
        getFullPathImage(path) {
            return process.env.VUE_APP_BASE_URL + path
        },
        clearCart() {
            this.items = []
            localStorage.removeItem('cart');
            this.dialogClear = false
        },
        calPrice(itemIndex) {
            console.log(itemIndex)
            return this.items[itemIndex].product.price * this.items[itemIndex].quantity
        },
        deleteItem(itemIndex) {
            this.items.splice(itemIndex, 1);
            localStorage.setItem("cart", JSON.stringify(this.items))
            
        }

    },
    computed: {
        subPrice() {
            if (this.items) {
                const total = this.items.reduce((partialSum, item) => partialSum + (parseFloat(item.price)), 0);
                return total;
            }
            return 0;
        }
    }
}
</script>
<style scoped lang="scss" src="@/styles/cart.scss">
</style>