<template>
    <div id="id">
        <router-view />
    </div>
</template>
<script>
export default{
    name: 'App',
    data() {
        return {}
    },  
    mounted() {},
    methods: {}
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Kantumruy+Pro&display=swap');
body {
    margin: 0;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    background-color: #ffffff;
}
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif,'Kantumruy Pro';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    border-left: 1px solid #f5f5f5;
    border-right: 1px solid #f5f5f5;
    text-align: center;
    color: #2c3e50;
    line-height: 1.5;
    min-width: 270px;
    max-width: 600px;
    margin: auto;
}
input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }
.alert {
    background-color: #fff;
}
</style>
